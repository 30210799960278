<template>
  <div class="iss-main-grid" ref="mainRef">
    <div class="iss-search-wrap">
      <div>
        <ul class="data-board">
          <li>
            <div>任务数量（个）</div>
            <div>{{ dataOverview.promoteCount }}</div>
          </li>
          <li>
            <div>推广人数（人）</div>
            <div>{{ dataOverview.promoteUserCount }}</div>
          </li>
          <li>
            <div>传播人数（人）</div>
            <div>{{ dataOverview.communicateUserCount }}</div>
          </li>
          <li>
            <div>留资人数（人）</div>
            <div>{{ dataOverview.submitFormCount }}</div>
          </li>
          <li>
            <div>访问人数（人）</div>
            <div>{{ dataOverview.visitorNumber }}</div>
          </li>
          <li>
            <div>访问次数（次）</div>
            <div>{{ dataOverview.visitCount }}</div>
          </li>
        </ul>
      </div>
      <search-form
        :items="items"
        :layout="{ sm: 24, md: 12, lg: 8 }"
        :default-value="search"
        @fnSearch="handleFnSearch"
      />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :url-params="{ entry: 2 }"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #promoteType="{ record }">
          <span v-if="record.promoteType == 1">会议邀请</span>
          <span v-else-if="record.promoteType == 2">{{
            record.resourceType === 'file' ? '资料推广' : '图文任务'
          }}</span
          ><span v-else-if="record.promoteType == 5">裂变海报</span>
        </template>

        <template #communicateUserCount="{ record }">
          <span v-if="record.promoteType === 5 || record.isFission">
            {{ record.communicateUserCount }}
          </span>
          <span v-else>-</span>
        </template>

        <template #conversionNumber="{ record }">
          <span v-if="record.resourceType === 'article'">-</span>
          <span v-else-if="record.promoteUrlType === '0'">-</span>
          <span v-else>{{ record.conversionNumber }}</span>
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
      </grid>
    </div>
  </div>
</template>

<script>
import Grid from '@/components/grid';
import promoteApi from '@/api/promote';
import boardApi from '@/api/board';
// import { message } from 'ant-design-vue';
import { reactive, ref, toRefs, watchEffect } from 'vue';
import Operation from '@/components/operation';
import SearchForm from '@/components/searchForm';
import { useRouter } from 'vue-router';
import { local } from '@/utils/storage';

export default {
  components: {
    Grid,
    Operation,
    SearchForm,
  },
  setup() {
    const gridRef = ref();
    const router = useRouter();

    const state = reactive({
      search: { campaignName: '', promoteType: '', resourceType: '' },
      dataOverview: {
        communicateUserCount: '',
        conversionCount: null,
        conversionNumber: null,
        conversionRate: null,
        promoteCount: '',
        promoteType: null,
        promoteUserCount: '',
        statDate: null,
        submitFormCount: '',
        visitCount: '',
        visitorNumber: '',
      },
    });

    watchEffect(() => {
      state.search.resourceType = state.search.promoteType;
      ['article', 'file'].includes(state.search.resourceType) &&
        (state.search.promoteType = 2);
    });

    // 数据总览接口
    boardApi.dateOverviewe('', {}).then(records => {
      state.dataOverview = records;
    });

    return {
      ...toRefs(state),
      items: [
        { key: 'promoteName', label: '任务名称' },
        {
          key: 'promoteType',
          label: '任务类型',
          type: 'select',
          dataset: [
            { label: '所有类别', value: '' },
            { label: '会议邀请', value: '1' },
            { label: '资料推广', value: 'file' },
            { label: '图文任务', value: 'article' },
            { label: '裂变海报', value: '5' },
          ],
        },
      ],
      columns: [
        {
          dataIndex: 'promoteName',
          title: '任务名称',
          width: 220,
          ellipsis: true,
        },
        {
          dataIndex: 'promoteType',
          title: '任务类型',
          slots: { customRender: 'promoteType' },
          width: 120,
        },
        { dataIndex: 'createTime', title: '创建时间', width: 180 },
        {
          dataIndex: 'createUserName',
          title: '创建人',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'promoterNumber',
          title: '推广人数',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'communicateUserCount',
          title: '传播人数',
          width: 100,
          slots: { customRender: 'communicateUserCount' },
          ellipsis: true,
          // sorter: (a, b) => {
          //   return a.communicateUserCount - b.communicateUserCount;
          // },
        },
        {
          dataIndex: 'visitorNumber',
          title: '访问人数',
          width: 100,
          ellipsis: true,
          // sorter: (a, b) => {
          //   return a.visitorNumber - b.visitorNumber;
          // },
        },
        {
          dataIndex: 'visitCount',
          title: '访问次数',
          width: 100,
          ellipsis: true,
          // sorter: (a, b) => {
          //   return a.visitCount - b.visitCount;
          // },
        },
        {
          dataIndex: 'conversionNumber',
          title: '转化人数',
          slots: { customRender: 'conversionNumber' },
          // sorter: (a, b) => {
          //   return a.conversionNumber - b.conversionNumber;
          // },
        },

        {
          dataIndex: 'id',
          fixed: 'right',
          title: '操作',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],
      //添加按钮
      btnOperation: [],
      // 操作按钮配置
      options: [
        {
          type: 'analysis',
          label: '推广数据',
          icon: 'EyeOutlined',
          permission: 'integrating:analysis',
          fnClick: record => {
            local.set('configData', record);
            router.push({
              name: 'TaskDetail',
            });
          },
        },
      ],
      handleFnSearch: value => {
        state.search = value;
      },

      // 表格数据请求链接
      url: promoteApi.pageUrl,
      gridHeight: document.body.clientHeight - 404,
      gridRef,

      formUpdate: formVisible => {
        state.formVisible = formVisible;
      },
    };
  },
};
</script>
<style lang="less" scoped>
.iss-main-grid {
  //height: calc(100vh - 111px);
  //overflow: auto;
}
.modal {
  .ant-btn {
    width: 85%;
  }
}
.not-open {
  text-align: center;
  padding: 100px 0px 100px 0px;
}
.qrcode-ver {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.qrcode-url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #5979f8;
  border-color: #5979f8;
}
.vue-qr {
  .qr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.item-content_demo {
  /*height:670px;*/
  /* width:375px;*/
  height: 100%;
  width: 100%;
  position: relative;
  img {
    width: 100%;
    /*height: 100%;*/
  }
  .qrcode {
    position: absolute;
  }
}
.iss-search-wrap {
  .data-board {
    border-bottom: 1px #e5e6eb solid;
    padding-bottom: 12px;
    display: flex;
    li {
      width: 33.33%;
      & > div:nth-of-type(1) {
        margin-bottom: 10px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1d2129;
      }
      & > div:nth-of-type(2) {
        font-size: 18px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #1d2129;
      }
    }
  }
}
</style>
